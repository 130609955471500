export const berichteTypen = {
    Bereichsauswertung: 'bereichsauswertung',
    Einsatzortauswertung: 'einsatzortauswertung',
    Lohnabrechnung: 'lohnabrechnung',
    Monatskosten: 'monatskosten',
    Monatsuebersicht: 'monatsuebersicht',
    Nachrichten: 'nachrichtenliste',
    Personalkosten: 'personalkosten',
    Stundenauswertung: 'stundenauswertung',
    Stundenbereiche: 'stundenbereiche',
    Stundenliste: 'stundenliste',
    Stundenzettel: 'stundenzettel',
    Wochensuebersicht: 'wochenuebersicht',
};
