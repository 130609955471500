import Keycloak from 'keycloak-js';
import { checkLastVisitedOnLogin, storeLastVistiedOnLogout } from '../startupUrlCheck.js';
import dayjs from './dayjs.js';
import ApplicationLauncher from './applicationLauncher.js';
import CookieService from './cookieService.js';
import SystemDialogService from './systemDialogService.js';
import { Roles } from '../../../domain/konstanten/Roles.js';

const cookieService = new CookieService();
window.myVars = window.myVars || {};
window.myHandlers = window.myHandlers || {};
window.myHandlers.acceptAGB = acceptAGB;
window.myHandlers.denyAGB = denyAGB;

/**
 * Initialisiert den Keycloak Service für das Session Management
 * - der Keycloak code wird im HTML Head geladen (../../dist/main.html Zeile 9)
 */
async function initKeycloak() {
    const response = await fetch('/assets/keycloak-config.json');
    const config = await response.json();
    const keycloak = new Keycloak(config);

    // force update Token when online again
    window.addEventListener('online', () => {
        keycloak.updateToken(-1).then((wasUpdated) => {
            cookieService.setCookie('access_token', keycloak.token);
        }).catch(async () => {
            await sessionLogout();
        });
    });
    // force update Token when opening app again
    document.addEventListener('visibilitychange', function() {
        if (document.visibilityState === 'visible') {
            keycloak.updateToken(-1).then((wasUpdated) => {
                cookieService.setCookie('access_token', keycloak.token);
            }).catch(async () => {
                await sessionLogout();
            });
        }
      });

    keycloak.onAuthSuccess = async () => {
        cookieService.setCookie('access_token', keycloak.token);
        const time = keycloak.tokenParsed.exp * 1000 - new Date().getTime();
        updateToken(Math.round(time));
        await checkUserRoles();
        ApplicationLauncher.instance.setReady();
        await checkNeolohnAGB(keycloak.tokenParsed);

        checkLastVisitedOnLogin();
    };

    async function checkUserRoles() {
        // check if roles exist
        if (!keycloak.tokenParsed.resource_access?.neolohn?.roles) {
            await invalidUserLogout();
        }
        window.myVars.UserRoles = keycloak.tokenParsed.resource_access.neolohn.roles;
        window.myVars.Username = keycloak.tokenParsed.name;
        window.myVars.UserNeolohnId = keycloak.tokenParsed?.neolohnId ? keycloak.tokenParsed?.neolohnId : null;
        // Falls nur Mitarbeiter, dann zur App wechseln
        if (window.myVars.UserRoles.includes(Roles.Mitarbeiter)
            && window.myVars.UserRoles.length === 1
            && !window.location.href.split('/').includes('ma-app')) {
            window.location.href = `${window.location.origin}/ma-app`;
        }
        // Falls nur Zeiterfassung, dann zur Zeiterfassung wechseln
        if (window.myVars.UserRoles.includes(Roles.Zeiterfassung)
            && window.myVars.UserRoles.length === 1
            && !window.location.href.split('/').includes('zeiterfassung')) {
            window.location.href = `${window.location.origin}/zeiterfassung`;
        }
        // Falls nur Dienstplaner, dann zum Dienstplan wechseln
        if (window.myVars.UserRoles.includes(Roles.Dienstplaner)
            && window.myVars.UserRoles.length === 1
            && !window.location.href.split('/').includes('dienstplan')) {
            window.location.href = `${window.location.origin}/dienstplan`;
        }

        // Falls der Benutzer kein Mitarbeiter ist, müssen wir ihn aus der Mitarbeiter App ausloggen...
        if (window.location.href.split('/').includes('ma-app')
            && !window.myVars.UserRoles.includes(Roles.Mitarbeiter)) {
                await invalidUserLogout();
        }
        // Falls der Benutzer kein Sachbearbeiter oder Betriebsleiter User ist, müssen wir ihn aus neolohn ausloggen...
        if (window.location.href.split('/').includes('neolohn')
            && !window.myVars.UserRoles.includes(Roles.Sachbearbeiter)
            && !window.myVars.UserRoles.includes(Roles.DienstplanerOhneBudget)
            && !window.myVars.UserRoles.includes(Roles.DienstplanerMitBudget)
            && !window.myVars.UserRoles.includes(Roles.Zeitenpfleger)) {
                await invalidUserLogout();
        }
        // Falls der Benutzer kein Zeiterfassung User ist, müssen wir ihn aus der Zeiterfassung ausloggen...
        if (window.location.href.split('/').includes(Roles.Zeiterfassung)
            && !window.location.href.split('/').includes('ma-app') // verwmeide ungewolltes ausloggen in der ma-app/zeiterfassung
            && !window.myVars.UserRoles.includes('zeiterfassung')) {
                await invalidUserLogout();
        }
    }

    async function invalidUserLogout() {
        setTimeout(() => keycloak.logout(), 10 * 1000);
        await SystemDialogService.instance.displayAsync('invalid-user-dialog');
        keycloak.logout();
    }

    // wir forcieren das Update Token Event mit unseren eigenen Timer.
    function updateToken(timeout) {
        setTimeout(() => {
            keycloak.updateToken(-1).then((wasUpdated) => {
                cookieService.setCookie('access_token', keycloak.token);
            }).catch(async () => {
                await sessionLogout();
            });
        }, timeout - 2 * 1000);
    }

    keycloak.onAuthRefreshSuccess = () => {
        const time = keycloak.tokenParsed.exp * 1000 - new Date().getTime();
        updateToken(Math.round(time));
    };

    keycloak.onAuthRefreshError = async () => {
        await sessionLogout();
    };

    keycloak.onAuthError = async () => {
        await sessionLogout();
    };

    keycloak.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}/assets/silent-check-sso.html`,
    }).then((authenticated) => {
        if (!authenticated) {
            keycloak.login();
        }
    }).catch(() => {
        keycloak.login();
    });

    document.getElementById('logout-link').addEventListener('click', () => {
        cookieService.deleteCookie();
        storeLastVistiedOnLogout();
        keycloak.logout();
    });

    // 15 min idle timeout
    const timeoutLogout = 15 * 60 * 1000;
    let timeoutIdle;
    let timeoutCounter = 0;
    const timeoutReset = () => {
        document.getElementById('logout-timer-div').style.display = 'none';
        document.getElementById('logout-logout').style.display = 'inline-block';
        clearTimeout(timeoutIdle);
        timeoutCounter = 0;
        timeoutIdle = setTimeout(idleLogout, timeoutLogout);
    };
    // für die ma-app und zeiterfassung brauchen wir keinen idle timeout...
    if (!window.location.href.split('/').includes('ma-app') && !window.location.href.split('/').includes('zeiterfassung')) {
        timeoutIdle = setTimeout(idleLogout, timeoutLogout);
        const logoutTimerHTML = document.getElementById('logout-timer');
        // reset timer when user interacts with app
        ['click', 'mouseover', 'keyup'].forEach((event) => {
            document.body.addEventListener(event, timeoutReset);
        });
        // update Timer Interval
        setInterval(() => {
            timeoutCounter += 500;
            const remainingSeconds = timeoutLogout - timeoutCounter;
            // Wenn nur noch 5 Minuten übrig sind, zeigen wir den Timer an
            if (remainingSeconds <= 5 * 60 * 1000) {
                document.getElementById('logout-logout').style.display = 'none';
                document.getElementById('logout-timer-div').style.display = 'block';
                logoutTimerHTML.innerText = dayjs.duration(remainingSeconds, 'milliseconds').format('mm:ss');
                // Wenn nur noch 1 Minute übrig ist, zeigen wir den Timer in rot an.
                if (remainingSeconds <= 1 * 60 * 1000) {
                    logoutTimerHTML.classList.add('logout-last-minute');
                } else {
                    logoutTimerHTML.classList.remove('logout-last-minute');
                }
            }
        }, 500);
    }

    /**
     * Beim Idle Logout, haben wir immer noch ein gültiges Token, das wir so einfach nicht invalidieren können,
     * deshalb wird der User direkt ausgeloggt.
     */
    async function idleLogout() {
        cookieService.deleteCookie();
        storeLastVistiedOnLogout();
        keycloak.logout();
    }

    /**
     * Session Timeout, User wird benachrichtigt, warum er ausgeloggt wird.
     */
    async function sessionLogout() {
        keycloak.onAuthRefreshError = null;
        keycloak.onAuthRefreshSuccess = null;
        keycloak.onAuthError = null;
        keycloak.onAuthSuccess = null;
        cookieService.deleteCookie();
        // Der Client soll sich spätestens nach einer halben Stunde ganz abmelden, falls der User den Button nicht klickt.
        setTimeout(() => keycloak.logout(), 30 * 60 * 1000);
        await SystemDialogService.byID('app-timeout-dialog', 11000).displayAsync('token-timeout-dialog', { closeOnBackdrop: false });
        keycloak.logout();
    }

    /**
     * Blendet den AGB Dialog inklusive link zur AGB PDF ein, muss akzeptiert werden, sonst logout.
     * @param {*} tokenParsed 
     * @returns 
     */
    async function checkNeolohnAGB(tokenParsed) {
        try {
            // erstmal nur auf neolohn
            if (!window.location.href.split('/').includes('neolohn')) {
                return;
            }
            // falls AGB schon akzeptiert, kein Dialog anzeigen.
            if (new Date(tokenParsed?.AGB) >= new Date(2024, 2, 6)) {
                return;
            }
            const result = await SystemDialogService.byID('agb-dialog', '12000').displayAsync('neolohn-agb-dialog', { closeOnBackdrop: false });
            if (result.data.agb) {
                await fetch(`/neolohn/api/benutzer/${tokenParsed?.sub}/agb/confirm`, { method: 'POST' });
                return;
            }
            keycloak.logout();
        } catch (error) {
            console.log(error);
            keycloak.logout();
        }
    }
}

function acceptAGB() {
    SystemDialogService.byID('agb-dialog').confirm({
        agb: true
    });
}

function denyAGB() {
    SystemDialogService.byID('agb-dialog').confirm({
        agb: false
    });
}

export { initKeycloak };
